import React, { useState, useEffect, useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Typography,
  InputLabel,
  InputBase,
  NativeSelect,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import CreateNewUser from '../api/CreateNewUser';
import ButtonComponent from './common/ButtonComponent';
import AdditionalDetailsRadio from './common/AdditionalDetailsRadio';
import { ACCOUNT_TYPES, CANADA_HCPS, COLORS } from '../utils/applicationConstants';
import AccountContext from '../contexts/AccountContext';
import { isValidNpi, isValidZipCode, isValidPhoneNumber } from '../utils/helpers';
import AddLocationForPendingAccountModal from './AddLocationForPendingAccountModal';

const AdditionalDetailsForm = () => {
  const med = useMediaQuery('(min-width:1060px)');
  const useStyles = makeStyles({
    additionalDetailsInput: {
      border: '1px solid #C4C4C4',
      borderRadius: '15px',
      boxSizing: 'border-box',
      padding: '0.5rem',
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '100%',
      minHeight: '100vh',
      paddingBottom: '20px',
    },
    form: {
      width: med ? '470px' : '95%',
    },
    outerContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    flexRow: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      width: '100%',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      width: '100%',
    },
    inputLabel: {
      paddingBottom: '0.5rem',
      paddingTop: '1rem',
      color: '#58595B',
    },
    select: {
      border: '1px solid #C4C4C4',
      borderRadius: '15px',
      boxSizing: 'border-box',
      padding: '0.5rem',
      width: '100%',
      textDecoration: 'none',

      '&:focus': {
        backgroundColor: 'none',
      },
    },
    pageTitle: {
      fontWeight: '400',
      fontSize: '2rem',
      lineHeight: '34.5px',
      fontFamily: 'Roboto Condensed',
      marginTop: '4rem',
    },
    formHeader: {
      fontWeight: '700',
      fontSize: '22px',
      marginTop: '2rem',
      fontFamily: 'Roboto Condensed',
    },
    formText: {
      fontWeight: '300',
      fontSize: '20px',
      marginTop: '1rem',
      fontFamily: 'Roboto Condensed',
    },
    button: {
      padding: '15px 40px 15px 40px',
      backgroundColor: '#0B7AEF',
      color: 'white',
      borderRadius: '30px',
      marginTop: '1.5rem',

      '&:hover': {
        backgroundColor: '#0B7AEF',
        color: 'white',
      },

      '&:disabled': {
        backgroundColor: '#e7e7e7',
      },
    },
    errorMessage: {
      color: 'red',
      paddingTop: '1rem',
    },
    buttonRow: {
      display: 'flex',
      alignItems: 'center',
    },
    backButton: {
      padding: '36px 40px 15px 40px',
      color: COLORS.AZURE_RAD,
      textDecoration: 'underline',
    },
    accountTypeButtonGroup: {
      marginTop: '2rem',
    },
    cancelBtn: {
      position: 'relative',
      top: '5px',
      left: '10px',
    },
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAcctManager, setIsActManager] = useState(false);
  const [accountTypeSelected, setAccountTypeSelected] = useState(false);
  const [showAddLocationView, setAddLocationView] = useState(false);
  const [selectedUiLocations, setSelectedUiLocations] = useState([]);
  const [unifiedLocationToLocationsMapping, setUnifiedLocationToLocationsMapping] = useState([]);
  const [formData, setFormData] = useState({
    prefix: '',
    firstName: '',
    lastName: '',
    npi: '',
    locationIds: [],
    doesNotHavenpi: '',
    zip: '',
    workPhone: '',
    cellPhone: '',
    notificationSettings: '',
    accountType: '',
  });
  const [isInvalidNPI, setIsInvalidNPI] = useState(false);
  const [isInvalidZip, setIsInvalidZip] = useState(false);
  const [isInvalidWorkPhone, setIsInvalidWorkPhone] = useState(false);
  const [isInvalidCellPhone, setIsInvalidCellPhone] = useState(false);
  const { account } = useContext(AccountContext);
  useEffect(async () => {
    try {
      setFormData({
        ...formData,
        doesNotHavenpi: (formData.accountType === ACCOUNT_TYPES.AccountManager),
      });
      if (formData.accountType === ACCOUNT_TYPES.AccountManager) {
        setIsActManager(true);
      } else {
        setIsActManager(false);
      }
    } catch (e) {
      Error(e);
    }
  }, [formData.accountType]);

  async function submitForm() {
    setIsSubmitted(true);
    try {
      if (formData.accountType === ACCOUNT_TYPES.HCP && !isValidNpi(formData.npi)) {
        setIsInvalidNPI(true);
      } else {
        setIsInvalidNPI(false);
      }
      if (!isValidPhoneNumber(formData.workPhone)) {
        setIsInvalidWorkPhone(true);
      } else {
        setIsInvalidWorkPhone(false);
      }
      if (!isValidPhoneNumber(formData.cellPhone)) {
        setIsInvalidCellPhone(true);
      } else {
        setIsInvalidCellPhone(false);
      }
      if (!isValidZipCode(formData.zip)) {
        /*
          -- This is to skip validation for Canada hcps during migration
          -- To REMOVE AFTER MIGRATION
        */
        if (CANADA_HCPS.some((hcpEmail) => hcpEmail === account.email)) {
          setIsInvalidZip(false);
        } else {
          setIsInvalidZip(true);
        }
      } else {
        setIsInvalidZip(false);
      }
      if (
        (formData.accountType === ACCOUNT_TYPES.HCP && !isValidNpi(formData.npi))
        || !isValidZipCode(formData.zip)
        || !isValidPhoneNumber(formData.workPhone)
        || !isValidPhoneNumber(formData.cellPhone)) {
        setIsSubmitted(false);
        return;
      }
      if (formData.accountType === ACCOUNT_TYPES.AccountManager
        && formData.locationIds.length === 0) {
        setIsSubmitted(false);
        return;
      }
      const res = await CreateNewUser(formData);
      if (res.status === 200) {
        setIsSuccess(true);
        return;
      }
    } catch (e) {
      setIsSubmitted(false);
    }
  }

  function updateAccountType(newType) {
    setFormData({
      ...formData,
      accountType: newType,
    });
  }
  async function addLocationIdsToList(locationIds) {
    setFormData({
      ...formData,
      locationIds: formData.locationIds.concat(locationIds),
    });
  }
  async function addUiLocationToSelectedList(uiLocation) {
    const newUiLocationList = selectedUiLocations.concat(uiLocation);
    setSelectedUiLocations(newUiLocationList);
  }

  async function removeUnifiedLocationFromLocationLists(location) {
    let locationIdsToRemove = [];
    unifiedLocationToLocationsMapping.forEach((value) => {
      if (value.unifiedLocationId === location.uiLocationId) {
        locationIdsToRemove = value.locationIds;
      }
    });
    const newUnifiedLocationToLocationsMapping = unifiedLocationToLocationsMapping
      .filter((value) => value.unifiedLocationId !== location.uiLocationId);
    const newSelectedLocationList = selectedUiLocations
      .filter((value) => value.uiLocationId !== location.uiLocationId);
    const newFormDataLocationList = formData.locationIds
      .filter((value) => !locationIdsToRemove.includes(value));
    setFormData({ ...formData, locationIds: [...newFormDataLocationList] });
    setSelectedUiLocations(newSelectedLocationList);
    setUnifiedLocationToLocationsMapping(newUnifiedLocationToLocationsMapping);
  }
  async function removeLocationFromLocationLists(location) {
    if (formData.locationIds.length === 1) {
      setFormData({
        ...formData,
        locationIds: [],
      });
      setSelectedUiLocations([]);
    } else {
      const newLocationList = formData.locationIds
        .filter((value) => value !== location.uiLocationId);
      const newSelectedLocationList = selectedUiLocations
        .filter((value) => value.uiLocationId !== location.uiLocationId);
      setFormData({ ...formData, locationIds: [...newLocationList] });
      setSelectedUiLocations(newSelectedLocationList);
    }
  }
  async function removeLocationHandler(location) {
    if (location.isUnifiedLocation) {
      removeUnifiedLocationFromLocationLists(location);
    } else {
      removeLocationFromLocationLists(location);
    }
  }
  function handleOnChange(event) {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    const userInformationCopy = {
      ...formData,
    };
    userInformationCopy[inputName] = inputValue;
    setFormData(userInformationCopy);
  }
  const classes = useStyles();
  if (isSuccess) {
    return <Redirect to="/details/success" />;
  }
  if (accountTypeSelected) {
    return (

      <form id="form" className={classes.formContainer}>
        <div className={classes.form}>

          <Typography className={classes.pageTitle}>
            AlphaID™
            <br />
            Results Portal
          </Typography>
          <Typography className={classes.formHeader}>
            Additional Details
          </Typography>
          <Typography className={classes.formText}>
            Please enter your information below to finalize your account setup
          </Typography>
          <div className={classes.flexRow}>
            <div
              style={{
                width: '25%',
              }}
              className={classes.inputContainer}
            >
              <InputLabel htmlFor="prefix" className={classes.inputLabel}>Prefix</InputLabel>
              <NativeSelect
                style={{
                  width: '80%',
                }}
                name="prefix"
                value={formData.prefix}
                onChange={handleOnChange}
                disableUnderline
                className={classes.select}
              >
                <option hidden value=""> </option>
                <option value="Dr.">Dr.</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
              </NativeSelect>
            </div>
            <div className={classes.inputContainer}>
              <InputLabel htmlFor="firstName" className={classes.inputLabel}>First name</InputLabel>
              <InputBase required id="firstName" name="firstName" value={formData.firstName} onChange={handleOnChange} className={classes.additionalDetailsInput} />
            </div>
          </div>

          <InputLabel htmlFor="lastName" className={classes.inputLabel}>Last name</InputLabel>
          <InputBase id="lastName" name="lastName" value={formData.lastName} onChange={handleOnChange} className={classes.additionalDetailsInput} />

          {!isAcctManager && <InputLabel htmlFor="npi" className={classes.inputLabel}>NPI number</InputLabel>}
          {!isAcctManager && (
            <InputBase
              id="npi"
              name="npi"
              value={formData.npi}
              onChange={handleOnChange}
              disabled={formData.doesNotHavenpi}
              className={classes.additionalDetailsInput}
              placeholder="10 digits only (e.g., 1234567890)"
            />
          )}
          {isAcctManager && <InputLabel htmlFor="location" className={classes.inputLabel}>Add Your Practice&apos;s Location(s) (Required)</InputLabel>}
          {isAcctManager
            && (
              <>
                <ButtonComponent
                  onClick={() => { setAddLocationView(true); }}
                >
                  Add a location to this practice
                </ButtonComponent>
                <AddLocationForPendingAccountModal
                  modalOpen={showAddLocationView}
                  setModalOpen={setAddLocationView}
                  addLocationIdsToList={addLocationIdsToList}
                  locationIds={formData.locationIds}
                  unifiedLocationToLocationsMapping={unifiedLocationToLocationsMapping}
                  setUnifiedLocationToLocationsMapping={setUnifiedLocationToLocationsMapping}
                  addUiLocationToSelectedList={addUiLocationToSelectedList}
                />
              </>
            )}
          {isAcctManager && selectedUiLocations.length > 0 && selectedUiLocations.map((loc) => (
            <li key={loc.uiLocationId} className={classes.responseText}>
              {loc.address}
              <Cancel className={classes.cancelBtn} onClick={() => removeLocationHandler(loc)} />
            </li>
          ))}
          <InputLabel htmlFor="zip" className={classes.inputLabel}>Zip</InputLabel>
          <InputBase
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleOnChange}
            className={classes.additionalDetailsInput}
            placeholder="5 digits (e.g., 75001)"
          />
          <InputLabel htmlFor="workPhone" className={classes.inputLabel}>Work phone</InputLabel>
          <InputBase
            id="workPhone"
            name="workPhone"
            value={formData.workPhone}
            onChange={handleOnChange}
            className={classes.additionalDetailsInput}
            placeholder="10 digits only, no special symbols  (e.g., 1234567890)"
          />
          <InputLabel htmlFor="cellPhone" className={classes.inputLabel}>Cell phone</InputLabel>
          <InputBase
            id="cellPhone"
            name="cellPhone"
            value={formData.cellPhone}
            onChange={handleOnChange}
            className={classes.additionalDetailsInput}
            placeholder="10 digits only, no special symbols  (e.g., 1234567890)"
          />
          <Typography className={classes.formText}>
            Would you like to start receiving email notifications about your AlphaID
            test results when the notification feature becomes available?
          </Typography>
          <InputLabel htmlFor="notificationSettings" className={classes.inputLabel}>Notification settings</InputLabel>
          <NativeSelect
            name="notificationSettings"
            value={formData.notificationSettings}
            onChange={handleOnChange}
            disableUnderline
            className={classes.select}
          >
            <option hidden value=""> </option>
            { /* eslint-disable-next-line react/jsx-boolean-value */}
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </NativeSelect>
          {isInvalidNPI
            && (
              <Typography className={classes.errorMessage}>
                Error: NPI must be a valid 10 digit NPI (eg. 1234567890)
              </Typography>
            )}
          {isInvalidZip
            && (
              <Typography className={classes.errorMessage}>
                Error: Zip code must be 5 digits (eg. 75001)
              </Typography>
            )}
          {isInvalidWorkPhone
            && (
              <Typography className={classes.errorMessage}>
                Error: Work Phone must be 10 digits (eg. 1234567890)
              </Typography>
            )}
          {isInvalidCellPhone
            && (
              <Typography className={classes.errorMessage}>
                Error: Cell Phone must be 10 digits (eg. 1234567890)
              </Typography>
            )}
          <div className={classes.buttonRow}>
            {!isAcctManager && <Button disabled={!Object.values(formData).every((o) => o !== '') || isSubmitted} onClick={submitForm} className={classes.button}>Finish</Button>}

            {isAcctManager && <Button disabled={formData.prefix === '' || formData.firstName === '' || formData.lastName === '' || formData.zip === '' || formData.workPhone === '' || formData.cellPhone === '' || formData.timeZone === '' || formData.notificationSettings === '' || formData.locationIds.length === 0 || isSubmitted} onClick={submitForm} className={classes.button}>Finish</Button>}
            <Button
              className={classes.backButton}
              onClick={() => setAccountTypeSelected(false)}
            >
              Back
            </Button>
          </div>
        </div>
      </form>
    );
  }
  return (
    <div className={classes.formContainer}>
      <div className={classes.form}>
        <Typography className={classes.pageTitle}>
          AlphaID™
          <br />
          Results Portal
        </Typography>
        <Typography className={classes.formHeader}>
          A few more steps...
        </Typography>
        <Typography className={classes.formText}>
          Which of the following best describes how you will use the AlphaID Results Portal?
        </Typography>
        <div className={classes.accountTypeButtonGroup}>
          <AdditionalDetailsRadio
            updateAccountType={updateAccountType}
            isActive={formData.accountType === ACCOUNT_TYPES.HCP}
            type="hcp"
          />
          <AdditionalDetailsRadio
            updateAccountType={updateAccountType}
            isActive={formData.accountType === ACCOUNT_TYPES.AccountManager}
            type="acctManager"
          />
        </div>
        <Button onClick={() => setAccountTypeSelected(true)} disabled={formData.accountType === null || formData.accountType === ''} className={classes.button}>Next</Button>
      </div>
    </div>
  );
};

export default AdditionalDetailsForm;
