import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import ctaBannerBackground from '../../images/ctaBannerBackground.svg';
import { COLORS } from '../../utils/applicationConstants';
import InterstitialPrompt from './InterstitialPrompt';

const useStyles = makeStyles((theme) => ({
  ctaBannerRoot: {
    height: '248px',
    width: '470px',
    background: `url(${ctaBannerBackground}) no-repeat`,
    borderRadius: '15px',
    backgroundColor: COLORS.PATTENS_BLUE,
    backgroundPosition: 'right',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  title: {
    color: COLORS.ABBEY,
    fontSize: '22px',
  },
  contentContainer: {
    paddingLeft: '38px',
    paddingTop: '41px',
    [theme.breakpoints.down('sm')]: {
      padding: '30px',
    },
  },
  buttonSize: {
    width: '179px',
  },
  copy: {
    width: '270px',
    lineHeight: '30px',
    marginBottom: '22px',
  },
  modalBackground: {
    backgroundColor: COLORS.WHITE,
    margin: '50px auto',
    width: '45%',
    padding: '50px',
    paddingBottom: '30px',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  secondaryButtonAsLink: {
    width: '179px',
    border: 'none',
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'white',
      color: COLORS.AZURE_RAD,
    },
  },
}));
const CtaBannerWithModal = ({ siteUrl }) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  return (
    <>
      <div className={classes.ctaBannerRoot}>
        <div className={classes.contentContainer}>
          <span className={classes.title}>Explore a treatment option</span>
          <p className={classes.copy}>
            Learn more about a treatment option for AATD.
          </p>
          <ButtonComponent
            onClick={() => setModal(true)}
            className={classes.buttonSize}
          >
            Learn More
          </ButtonComponent>
        </div>
      </div>
      <InterstitialPrompt
        modalOpen={modal}
        setModalOpen={setModal}
        copy='You will be directed to a product website to learn more about a treatment option for Alpha-1 antitrypsin deficiency. If you would like to continue to the site, click "OK."If you do not wish to leave this site, select "Close."'
        title="You are leaving the AlphaID Results Portal."
        siteUrl={siteUrl}
      />
    </>
  );
};

export default CtaBannerWithModal;
