import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Hidden, InputBase, makeStyles, CircularProgress } from '@material-ui/core';
import { Save as SaveIcon, Edit as EditIcon } from '@material-ui/icons';
import { ACCOUNT_TYPES } from '../utils/applicationConstants';
import { getPractice, removeHcpFromPractice, updatePracticeName, removeLocationFromPractice, willLocationRemovalHideResults } from '../api/PracticesService';
import { updateManagedAccountDetails, updateAccountManagerDetails } from '../api/AccountService';
import HcpManagerTable from './HcpManagerTable';
import PageHeader from './common/PageHeader';
import ButtonComponent from './common/ButtonComponent';
import AddUserToPracticeModal from './AddUserToPracticeModal';
import HcpTableMobile from './HcpTableMobile';
import RemoveUserFromPracticeModal from './RemoveUserFromPracticeModal';
import PracticeLocationTable from './PracticeLocationTable';
import AddLocationToPracticeModal from './AddLocationToPracticeModal';
import PracticeLocationTableMobile from './PracticeLocationTableMobile';
import EditManagedAccountModal from './EditManagedAccountModal';
import RemoveLocationFromPracticeModal from './RemoveLocationFromPracticeModal';
import AccountContext from '../contexts/AccountContext';
import EditAccountManagerModal from './EditAccountManagerModal';

const useStyles = makeStyles({
  input: {
    border: '1px solid #C4C4C4',
    borderRadius: '15px',
    boxSizing: 'border-box',
    padding: '0.5rem',
    width: '50%',
    marginLeft: '0.5rem',
    marginTop: '0rem',
  },
  button: {
    cursor: 'pointer',
  },
  subtitle: {
    fontSize: '24px',
  },
  inlineButton: {
    display: 'inline-flex',
    width: '30%',
    marginLeft: '1rem',
  },

});

const PracticeView = (props) => {
  const classes = useStyles();
  const { account } = useContext(AccountContext);
  const { practiceId } = props;
  const [completedFirstLoad, setCompletedFirstLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAddUserView, setAddUserView] = useState(false);
  const [showRemoveUserView, setRemoveUserView] = useState(false);
  const [selectedRemovableRow, setSelectedRemovableRow] = useState({});
  const [selectedRemovableLocationRow, setSelectedRemovableLocationRow] = useState({});
  const [willHideResults, setWillHideResults] = useState(false);
  const [showRemoveLocationView, setRemoveLocationView] = useState(false);
  const [practice, setPractice] = useState({});

  const [showEditUser, setShowEditUser] = useState(false);
  const [selectedEditableRow, setSelectedEditableRow] = useState({});
  // for Practice Name & editing open/close/save
  const [tempPracticeName, setTempPracticeName] = useState('');
  const [editingName, setEditingName] = useState('');
  const [showAddLocationView, setAddLocationView] = useState(false);
  // for Account Manager edit
  const [showEditAccountManager, setShowEditAccountManager] = useState(false);
  const onSaveOfAccountManager = async (data) => {
    await updateAccountManagerDetails(data);
    setShowEditAccountManager(false);
    const practiceResponse = await getPractice(practiceId);
    setPractice(practiceResponse);
  };

  // storing the account Ids in the practice
  // so that the addUser modal can check to see if the account already exists
  const accountIdsInPractice = useMemo(
    () => {
      if (practice && practice.tableRows) {
        return practice.tableRows.map((x) => x.accountId);
      }
      return [];
    },
    [practice, practice?.tableRows],
  );
  const editClick = () => {
    setTempPracticeName(practice.practiceName);
    setEditingName(true);
  };

  const saveNameChange = async () => {
    if (tempPracticeName.trim() !== practice.practiceName.trim()
      && tempPracticeName.trim().length > 0) {
      // go to server and save name change
      await updatePracticeName(practiceId, tempPracticeName);
      setPractice({
        ...practice,
        practiceName: tempPracticeName,
      });
    }
    setEditingName(false);
  };

  useEffect(async () => {
    setIsLoading(true);
    if (practiceId) {
      const practiceResponse = await getPractice(practiceId);
      setPractice(practiceResponse);
      setCompletedFirstLoad(true);
      setIsLoading(false);
    }
  }, [practiceId]);

  async function deleteHandler() {
    const { npi } = selectedRemovableRow;
    setIsLoading(true);
    setRemoveUserView(false);
    await removeHcpFromPractice(practiceId, npi);
    const practiceResponse = await getPractice(practiceId);
    setPractice(practiceResponse);
    setIsLoading(false);
  }

  async function editHandler(data) {
    await updateManagedAccountDetails({
      firstName: data.firstName.length > 0 ? data.firstName : selectedEditableRow.firstName,
      lastName: data.lastName.length > 0 ? data.lastName : selectedEditableRow.lastName,
      zip: data.firstName.zip > 0 ? data.zip : selectedEditableRow.zip,
      accountId: selectedEditableRow.accountId,
    });

    const practiceResponse = await getPractice(practiceId);
    setPractice(practiceResponse);

    setShowEditUser(false);
  }

  function deleteModalHandler(rowItem) {
    setSelectedRemovableRow(rowItem);
    setRemoveUserView(true);
  }
  function editModalHandler(rowItem) {
    setSelectedEditableRow(rowItem);
    setShowEditUser(true);
  }
  async function deleteLocationModalHandler(rowItem) {
    if (practiceId && rowItem.uiLocationId) {
      const response = await willLocationRemovalHideResults(
        rowItem.uiLocationId,
        rowItem.isUnifiedLocation,
        practiceId,
      );
      setWillHideResults(response.data.willHideResults);
    }
    setSelectedRemovableLocationRow(rowItem);
    setRemoveLocationView(true);
  }
  async function deleteLocationHandler() {
    const location = selectedRemovableLocationRow;
    setIsLoading(true);
    setRemoveLocationView(false);
    await removeLocationFromPractice(location.uiLocationId, location.isUnifiedLocation, practiceId);
    const practiceResponse = await getPractice(practiceId);
    setPractice(practiceResponse);
    setIsLoading(false);
  }
  if (!completedFirstLoad) {
    return <CircularProgress />;
  }

  return (
    <>
      <PageHeader>
        <b>Practice Name:</b>
        {
          editingName
            ? (
              <span>
                <InputBase
                  placeholder={practice.practiceName}
                  id="practiceName"
                  name="practiceName"
                  value={tempPracticeName}
                  onChange={(evt) => setTempPracticeName(evt.target.value)}
                  className={classes.input}
                />
                {' '}
                <SaveIcon onClick={saveNameChange} className={classes.button} />
              </span>
            )
            : (
              <span>
                {' '}
                {practice.practiceName}
                {' '}
                <EditIcon onClick={editClick} className={classes.button} />
              </span>
            )
        }
        <div className={classes.subtitle}>
          <b>Account Manager:</b>
          {' '}
          {practice.accountManagerFirstName}
          {' '}
          {practice.accountManagerLastName}
          {
            account.accountType === ACCOUNT_TYPES.GrifolsAdmin
            && (
              <span>
                <ButtonComponent
                  onClick={() => setShowEditAccountManager(true)}
                  className={classes.inlineButton}
                >
                  Edit Account Manager
                </ButtonComponent>
              </span>
            )
          }
        </div>

      </PageHeader>
      <p>
        Below is a list of all healthcare providers who have registered
        for the AlphaID Results Portal and who the portal administrator
        has aligned to your practice. As an Account Manager,
        you are able to see AlphaID screening results for all
        healthcare providers on this list in your Dashboard,
        but each of the providers on this list can only see their
        own results. You may make changes to the list below. If you need assistance,
        please visit Contact Us to contact the portal administrator.
      </p>
      <p>
        <b>You may only edit information for Managed (&quot;Ghost&quot;) Accounts.</b>
        Otherwise, reach out to the healthcare provider for updates to their own information.
      </p>
      <ButtonComponent
        onClick={() => { setAddUserView(true); }}
      >
        Add an HCP to this Practice
      </ButtonComponent>
      <Hidden smDown>
        <HcpManagerTable
          results={practice.tableRows}
          isTableLoading={isLoading}
          deleteModalHandler={deleteModalHandler}
          editModalHandler={editModalHandler}
        />
      </Hidden>
      <Hidden mdUp>
        <HcpTableMobile
          results={practice.tableRows}
          deleteModalHandler={deleteModalHandler}
          editModalHandler={editModalHandler}
          isManager
          isLabManager
        />
      </Hidden>
      <AddUserToPracticeModal
        practiceId={practice.practiceId}
        setNewPracticeResults={setPractice}
        modalOpen={showAddUserView}
        setModalOpen={setAddUserView}
        accountIdsInPractice={accountIdsInPractice}
      />
      <RemoveUserFromPracticeModal
        hcpRow={selectedRemovableRow}
        deleteHandler={deleteHandler}
        modalOpen={showRemoveUserView}
        setModalOpen={setRemoveUserView}
      />
      <h1>Practice Locations</h1>
      <p>
        Below is a list of all locations that have
        been aligned to your practice. You must add
        at least one location to your practice in order to receive
        results for HCPs who are a part of multiple practices.
        Should you need to make any changes to the list
        below, please visit Contact Us to contact the portal
        administrator and they will assist in adding to or removing from your practice.
      </p>
      <ButtonComponent
        onClick={() => { setAddLocationView(true); }}
      >
        Add a location to this practice
      </ButtonComponent>
      <Hidden smDown>
        <PracticeLocationTable
          results={practice.practiceLocations}
          isTableLoading={isLoading}
          deleteModalHandler={deleteLocationModalHandler}
        />
      </Hidden>
      <Hidden mdUp>
        <PracticeLocationTableMobile
          results={practice.practiceLocations}
          isTableLoading={isLoading}
          deleteModalHandler={deleteLocationModalHandler}
        />
      </Hidden>
      <AddLocationToPracticeModal
        modalOpen={showAddLocationView}
        setModalOpen={setAddLocationView}
        setNewPracticeResults={setPractice}
        practiceId={practiceId}
      />
      <RemoveLocationFromPracticeModal
        locationRow={selectedRemovableLocationRow}
        deleteHandler={deleteLocationHandler}
        modalOpen={showRemoveLocationView}
        setModalOpen={setRemoveLocationView}
        willHideResults={willHideResults}
      />
      <EditManagedAccountModal
        hcpRow={selectedEditableRow}
        modalOpen={showEditUser}
        setModalOpen={setShowEditUser}
        editHandler={editHandler}
      />
      <EditAccountManagerModal
        modalOpen={showEditAccountManager}
        setModalOpen={setShowEditAccountManager}
        practiceDetails={practice}
        saveAccountManager={onSaveOfAccountManager}
      />
    </>
  );
};

export default PracticeView;
