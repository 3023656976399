const COLORS = {
  AZURE: '#326295',
  WHITE: '#FFFFFF',
  WILD_SAND: '#F4F4F4',
  ABBEY: '#58595B',
  ABBEY_OPAQUE: 'rgba(88, 89, 91, 0.4)',
  MISCHKA: '#DDDEE5',
  CARDINAL: '#D02323',
  AZURE_RAD: '#0B7AEF',
  MERCURY: '#E9E9E9',
  ZIRCON: '#DDDEE6',
  TUTU: '#F9E5E5',
  SAZERAC: '#FEF4E0',
  PATTENS_BLUE: '#E2EFFD',
  SOLITUDE: '#E6ECF2',
  ORANGE: '#fba300',
  WEB_ORANGE: '#FAA300',
  SILVER: '#C4C4C4',
  BLACK: '#000000',
  ATHENS_GREY: '#DEDEDE',
  NAVY: '#081B2F',
  LIGHT_GREY: '#f8f9fa',
};

const UTILITY_NAVIGATION = [{
  name: 'explore a treatment option',
  link: '/explore',
  interstitial: true,
}, {
  name: 'contact us',
  link: '/contact-us',
},
];

const ACCOUNT_TYPES = {
  HCP: 1,
  AccountManager: 2,
  GrifolsRep: 3,
  Approver: 4,
  GrifolsAdmin: 5,
};

const CANADA_HCPS = ['david.germain.med@ssss.gouv.qc.ca', 'mopinsonneault@gmail.com', 'francois.beaucage.med@ssss.gouv.qc.ca', 'mountbensonmedical@gmail.com', 'kmorris@cheo.on.ca'];

const LIST_OF_US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  UM: 'Minor Outlying Islands',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'U.S. Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export {
  COLORS,
  UTILITY_NAVIGATION,
  ACCOUNT_TYPES,
  CANADA_HCPS,
  LIST_OF_US_STATES,
};
